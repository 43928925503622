import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { AdminPages, AdminQueryParams } from '../../../routes/adminRoutes';
import { PagerVariant } from './enums';
import type { Pageable, PagerCommonProps, PagerVariantTypeAndProps } from './types';

const PagerLoadMore = dynamic(() => import('./PagerLoadMore/PagerLoadMore'));
const PagerPreviousNext = dynamic(() => import('./PagerPreviousNext/PagerPreviousNext'));
const PagerPreviousNextLinkable = dynamic(
  () => import('./PagerPreviousNextLinkable/PagerPreviousNextLinkable')
);
const PagerInfiniteScroll = dynamic(() => import('./PagerInfiniteScroll/PagerInfiniteScroll'), {
  ssr: false // the infinite scroll pager relies on the IntersectionObserver WebAPI so no need to render it on the server
});
const PagerSeeAllLink = dynamic(() => import('./PagerSeeAllLink/PagerSeeAllLink'));
const PagerSeeAllModal = dynamic(() => import('./PagerSeeAllModal/PagerSeeAllModal'));
const PagerLoadMorePreviousNextLinkable = dynamic(
  () => import('./PagerLoadMorePreviousNextLinkable/PagerLoadMorePreviousNextLinkable')
);

const log = getLog(module);

interface Props<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> extends Pageable {
  /**
   * The style of pager to use.
   */
  variant?: PagerVariantTypeAndProps<RouteType, UrlQueryParamType>;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The children to pass through for display.
   */
  children?: React.ReactNode | null;
  /**
   * Param to set a custom element for this component.
   */
  as?: React.ElementType;
}

const variantToComponentMap: Record<
  PagerVariant,
  React.ComponentType<React.PropsWithChildren<PagerCommonProps>>
> = {
  infiniteScroll: PagerInfiniteScroll,
  loadMore: PagerLoadMore,
  previousNext: PagerPreviousNext,
  previousNextLinkable: PagerPreviousNextLinkable,
  none: null,
  seeAllLink: PagerSeeAllLink,
  seeAllModal: PagerSeeAllModal,
  loadMorePreviousNextLinkable: PagerLoadMorePreviousNextLinkable
};

/**
 * Pagination component for a list of objects queried from GraphQL.
 * Currently supports the following pagination variants: infinite scroll,
 * load more, previous/next, none, show all link, and show all modal.
 *
 * @author Adam Ayres
 */
const Pager = <
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
>({
  loadPage,
  pageInfo,
  variant = { type: PagerVariant.LOAD_MORE },
  className,
  children = null,
  as = null
}: Props<RouteType, UrlQueryParamType>): React.ReactElement => {
  const PagerComponent = variantToComponentMap[variant.type];
  const cx = useClassNameMapper();

  if (PagerComponent) {
    const { className: variantClassName, ...variantProps } = variant.props ?? {};
    return (
      <PagerComponent
        loadPage={loadPage}
        pageInfo={pageInfo}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        className={cx(className, variantClassName)}
        as={as}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...variantProps}
      />
    );
  } else {
    if (PagerVariant.NONE !== variant.type) {
      log.warn('No registered pager for variant type [%s]', variant.type);
    }
    return null;
  }
};

export default Pager;
