import type { QueryResult } from '@apollo/client';
import type { ListVariantTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import Pager from '@aurora/shared-client/components/common/Pager/Pager';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import PaginationHelper from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import { LoadingSize } from '@aurora/shared-client/types/enums';
import type { Connection, Entity } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { capitalizeFirst } from '@aurora/shared-utils/helpers/objects/StringHelper';
import React, { useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getItems } from '../../../helpers/list/EntityListHelper';
import type { ItemType } from '../../../types/enums';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import ItemView from '../ItemView/ItemView';

/**
 * Item List
 */
// eslint-disable-next-line @typescript-eslint/no-shadow
export interface ItemListCommonProps<ItemType, ViewVariantType> {
  /**
   * List variant to use for the collection.
   */
  listVariant?: ListVariantTypeAndProps<ItemType>;
  /**
   * The variant display style used when rendering the view component.
   */
  viewVariant?: ViewVariantType;
  /**
   * The style of pager to use.
   */
  pagerVariant?: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams>;
  /**
   * The number of items to display per page, also used to determine
   * the number of items used for first page even when pagination is not
   * used.
   */
  pageSize?: number;
}

/**
 * Props for `ItemList`.
 */
interface Props<
  EntityT,
  TypeT extends ItemType,
  ViewVariantT extends ItemViewTypeAndProps<TypeT, ItemViewVariant<TypeT>>,
  TData,
  TVariables
> {
  /**
   * The query result from a GraphQL call.
   */
  queryResult: QueryResult<TData, TVariables>;
  /**
   * The size of the loading indicator.
   */
  loadingSize?: LoadingSize;
  /**
   * The variant display style used when rendering the message list.
   */
  listVariant?: ListVariantTypeAndProps<EntityT>;
  /**
   * The style of pager to use.
   */
  pagerVariant?: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams>;
  /**
   * The number of items to show per page.
   */
  pageSize?: number;
  /**
   * The path to the items in the query results. This is used to get the items from
   * the query result for display and used for updating the items during pagination.
   * If left unspecified then the item path is assumed to simply be the key of the first
   * property inside the `data` object.
   */
  itemPath?: string;
  /**
   * Callback after pagination has occurred, can be used to augment API results
   * to "link" them to existing objects in the Apollo cache.
   */
  onUpdate?: ((newConnection: Connection) => TData) | undefined;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Allows for a component to be set below the list. This is done as a prop to allow
   * for the header to conditionally display based on if there are any results.
   */
  footer?: React.FC<React.PropsWithChildren<unknown>>;
  /**
   * A component to display when the list is empty.
   */
  empty?: React.FC<React.PropsWithChildren<unknown>>;

  /**
   * The path to the sub object in the query result, from where the items should
   * be taken. Used when the items to display are not part of the parent Node object.
   */
  querySubObject?: string;
  /**
   * Entity type for entity of type EntityT.
   */
  type: TypeT;
  /**
   * View variant type and props for the entity of type EntityT.
   */
  variant: ViewVariantT;
  /**
   * Callback for onScroll event over the list.
   */
  handleScroll?: (event: React.UIEvent) => void | undefined;
  /**
   * A reference to be set to the main div element
   */
  refToRoot?: React.MutableRefObject<HTMLDivElement>;
  /**
   * To specify if the list should be reversed.
   */
  useReverseList?: boolean;
}

/**
 * Creates a `List` from a `QueryResult`that contains an optional footer that contains a pager. The pager and the list
 * are rendered in the same wrapping div. This should only be used for cases when you want to iterate a list of entities
 * but don't want to use standard panels and want the pager alongside the lists. If standard panel is desired or the list
 * is rendered with a header, body, and footer section then use PaneledItemList.
 * The general markup generated from this list:
 * <code>
 *  <div class="<className>">
 *    <ul>
 *      <li><ItemView of EntityT</li>
 *      <li><ItemView of EntityT</li>
 *      <li><ItemView of EntityT</li>
 *      <li><ItemView of EntityT</li>
 *      <li><ItemView of EntityT</li>
 *    </ul>
 *    <Pager />
 * </code>
 *
 * @constructor
 *
 * @author Adam Ayres, Manish Shrestha
 */
const ItemList = <
  EntityT extends Entity,
  TypeT extends ItemType,
  ViewVariantT extends ItemViewTypeAndProps<TypeT, ItemViewVariant<TypeT>>,
  TData,
  TVariables
>({
  queryResult,
  loadingSize = LoadingSize.LG,
  listVariant = { type: ListVariant.UNSTYLED },
  pagerVariant = { type: PagerVariant.LOAD_MORE },
  pageSize = 5,
  itemPath,
  onUpdate,
  className,
  handleScroll,
  footer: Footer,
  empty: Empty,
  querySubObject,
  type,
  variant,
  refToRoot,
  useReverseList = false
}: Props<EntityT, TypeT, ViewVariantT, TData, TVariables>): React.ReactElement => {
  const cx = useClassNameMapper();
  const ref = useRef<HTMLDivElement>(null);

  const paginationHelper = PaginationHelper.fromQueryResult<TData, TVariables>(
    queryResult,
    pageSize,
    itemPath,
    onUpdate
  );

  /**
   * renders the entity.
   * @param entity the entity to render.
   */
  function renderEntity(entity: EntityT): React.ReactElement {
    return <ItemView<EntityT, TypeT, ViewVariantT> entity={entity} variant={variant} type={type} />;
  }

  const hasNextPage: boolean = paginationHelper?.pageInfo?.hasNextPage;
  const hasPreviousPage: boolean = paginationHelper?.pageInfo?.hasPreviousPage;
  const isPageable: boolean =
    pagerVariant.type === PagerVariant.PREVIOUS_NEXT ? hasNextPage || hasPreviousPage : hasNextPage;

  const DefaultFooter: React.FC<React.PropsWithChildren<unknown>> = () => {
    let finalPagerVariant = pagerVariant;

    if (pagerVariant.type === PagerVariant.SEE_ALL_MODAL) {
      finalPagerVariant = {
        type: pagerVariant.type,
        props: {
          listVariant,
          ...pagerVariant.props
        }
      };
    }

    if (isPageable) {
      return (
        <Pager
          loadPage={paginationHelper.loadPage}
          pageInfo={paginationHelper.pageInfo}
          variant={finalPagerVariant}
        />
      );
    }
    return null;
  };

  const FinalFooter = Footer !== undefined ? Footer : DefaultFooter;

  return (
    <QueryHandler<TData, TVariables> queryResult={queryResult} loadingSize={loadingSize}>
      {(): React.ReactNode => {
        const items: EntityT[] = getItems(queryResult, itemPath, querySubObject);
        if (useReverseList) {
          items.reverse();
        }

        const hasItems = items?.length > 0;
        const useEmpty = !hasItems && Empty;
        const showFooter: boolean = hasItems && isPageable;

        return (
          (hasItems || useEmpty) && (
            <div
              ref={refToRoot || ref}
              className={cx(className)}
              onScroll={handleScroll}
              data-testid={`${capitalizeFirst(type)}List`}
            >
              {useReverseList && showFooter && <FinalFooter />}
              <List<EntityT> items={items} variant={listVariant}>
                {renderEntity}
              </List>
              {useEmpty && <Empty />}
              {!useReverseList && showFooter && <FinalFooter />}
            </div>
          )
        );
      }}
    </QueryHandler>
  );
};

export default ItemList;
