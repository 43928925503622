import type { QueryResult } from '@apollo/client';
import { getPropertyByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { Connection } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Gets the items in the entity list to render based on the specified item path, query result and query sub object.
 * @param queryResult the query result from a GraphQL call.
 * @param itemPath the path to the items in the query result. This is used to get the items from
 *        the query result for display and used for updating the items during pagination.
 *        If left unspecified then the item path is assumed to simply be the key of the first
 *        property inside the `data` object.
 * @param querySubObject the path to the sub object in the query result, from where the items should
 * be taken. Used when the items to display are not part of the parent Node object.
 */
function getItems<EntityT, TData, TVariables>(
  queryResult: QueryResult<TData, TVariables>,
  itemPath: string,
  querySubObject: string
): EntityT[] {
  const finalItemPath = itemPath || Object.keys(queryResult?.data || {})[0] || '';

  const connection = getPropertyByPath<QueryResult<TData, TVariables>, Connection>(
    queryResult,
    `data.${finalItemPath}`
  );

  return querySubObject
    ? connection?.edges?.map(item => (item as EdgeNode<EntityT>)?.node?.[querySubObject])
    : connection?.edges?.map(item => (item as EdgeNode<EntityT>)?.node);
}

/**
 * Represents an edge node from a GraphQl response of items
 */
interface EdgeNode<ItemConnectionType> {
  /**
   * The node in the edge
   */
  node: ItemConnectionType;
}

// eslint-disable-next-line import/prefer-default-export
export { getItems };
